import React, { useState } from 'react';
import Layout from '../components/layout';

import SEO from '../components/seo';
import StandardOutboundButton from '../components/standard-outbound-button';

const KeepBuildingPage = () => {
  const [botNumber, setBotNumber] = useState('');
  const [botSecretKey, setBotSecretKey] = useState('');
  return (
    <>
      <Layout>
        <SEO
          title="Keep Building | Improve your Existing Code Championship Bot"
          description="You worked hard to build your bot. Keep working on it to make it even better!"
          keywords={['build', 'bot', 'continue']}
        />
        <h1>Keep Building Your Bot</h1>
        <div className="stripe-hr thin" />
        <section style={{ position: 'relative' }}>
          <h3>Enter your bot number here:</h3>
          <input
            value={botNumber}
            onChange={(event) => setBotNumber(event.target.value)}
          />
          <h3>Enter your bot Secret Key here:</h3>
          <input
            value={botSecretKey}
            onChange={(event) => setBotSecretKey(event.target.value)}
          />
          <section style={{
            width: (botNumber && botSecretKey && botSecretKey.length > 4) ? '50vw' : '0vw',
            overflowX: 'hidden',
            transition: 'width 400ms ease-in, left 400ms ease-in',
          }}
          >
            <StandardOutboundButton
              href={`${process.env.CODE_CHAMPIONSHIP_APP_URL}/#?player1=${botNumber}&botSecretKey=${botSecretKey}`}
              className="stripe-button secondary"
            >
              Let&apos;s Go!
            </StandardOutboundButton>
          </section>
        </section>
      </Layout>
    </>
  );
};

export default KeepBuildingPage;
